'use client'

import { useEffect, useRef } from 'react'

const CHECK_INTERVAL_MS = 5000 // "巡回" の間隔 (ミリ秒)
const MAX_RETRIES = 10 // 画像ごとの最大リトライ回数
const INITIAL_BACKOFF_MS = 2000 // リトライ初回の待ち時間 (ミリ秒)

/**
 * エラー画像の「巡回」と「リトライ」を繰り返し実施する
 *
 * - 巡回 (checkImages): エラー画像を探して retryMap に登録
 * - リトライ (processRetries): 登録済み画像を backoff ルールに従って再リクエスト
 * 
 * imgタグのステータス
 * - 読込中: complete=false, naturalWidth=0
 * - 読込完了: complete=true, naturalWidth>0
 * - 読込失敗: complete=true, naturalWidth=0
 */
export default function RetryImage() {
  // リトライ情報を保持する Map
  // retryMap: key=元のsrc, val={ retryCount, nextAttemptTime }
  const retryMapRef = useRef<
    Map<string, { retryCount: number; nextAttemptTime: number }>
  >(new Map())

  useEffect(() => {
    const retryMap = retryMapRef.current

    // コンポーネント アンマウントを検知するためのフラグ
    let unmounted = false

    /**
     * [巡回]
     * - ページ内 <img> を走査し、"complete=true & naturalWidth=0" の画像を retryMap に登録
     */
    function checkImages() {
      if (unmounted) return // アンマウント済みなら終了

      const images = document.querySelectorAll('img')
      images.forEach((img) => {
        if (!img.complete) return // ロード中ならスキップ

        if (img.naturalWidth === 0) {
          // "表示失敗/ブロック" と判断
          const originalSrc = img.dataset.originalSrc || img.src

          // すでに登録済みならスキップ
          if (!retryMap.has(originalSrc)) {
            // data-original-src を保存していなければ保存
            if (!img.dataset.originalSrc) {
              img.dataset.originalSrc = originalSrc
            }
            // retryCount=0, nextAttemptTime=今 で初期登録
            retryMap.set(originalSrc, {
              retryCount: 0,
              nextAttemptTime: Date.now(),
            })
          }
        }
      })
    }

    /**
     * [リトライ]
     * - retryMap 上の "リトライ可能な画像" を再読み込み
     * - backoff で nextAttemptTime を更新
     * - "RETRY_INTERVAL_MS" 後に再確認するように設定
     */
    function processRetries() {
      if (unmounted) return

      const now = Date.now()

      retryMap.forEach((info, originalSrc) => {
        const { retryCount, nextAttemptTime } = info

        if (retryCount >= MAX_RETRIES) {
          return // 上限到達
        }
        if (now < nextAttemptTime) {
          return // まだリトライ時刻に達していない
        }

        // 実際に <img> を検索して src を付け替え
        const targetImgs = document.querySelectorAll(
          `img[data-original-src="${originalSrc}"]`,
        )

        // すでに表示されているかどうかをチェック
        let error = false
        let loading = false
        targetImgs.forEach((image) => {
          const imgElement = image as HTMLImageElement
          // 読込中
          if (!imgElement.complete) {
            loading = true
          }
          // 読込失敗
          if (imgElement.complete && imgElement.naturalWidth === 0) {
            error = true
          }
        })

        if (!error) {
          if (!loading) {
            // エラーなく読み込み済み→ リトライ不要なのでmapから削除
            retryMap.delete(originalSrc)
            return
          }

          // エラーはないが、ロード中の場合は再読み込みをスキップし、前回と同じ時間だけ待機する
          if (!error && loading) {
            const newRetryCount = retryCount
            const backoffMs =
              Math.pow(2, newRetryCount - 1) * INITIAL_BACKOFF_MS
            const newNextAttemptTime = now + backoffMs
            retryMap.set(originalSrc, {
              retryCount: newRetryCount,
              nextAttemptTime: newNextAttemptTime,
            })
            return
          }
        }

        // === リトライ実行 ===
        targetImgs.forEach((e) => {
          const img = e as HTMLImageElement
          img.src = appendCacheBuster(originalSrc)
        })

        // リトライ回数と次回リトライ時刻を更新
        const newRetryCount = retryCount + 1
        const backoffMs = Math.pow(2, newRetryCount - 1) * INITIAL_BACKOFF_MS
        const newNextAttemptTime = now + backoffMs

        // retryMap 更新
        retryMap.set(originalSrc, {
          retryCount: newRetryCount,
          nextAttemptTime: newNextAttemptTime,
        })
      })
    }

    // 初回実行
    checkImages()
    processRetries()

    // 定期呼び出し
    const checkTimer = setInterval(checkImages, CHECK_INTERVAL_MS)
    const retryTimer = setInterval(processRetries, INITIAL_BACKOFF_MS)

    // アンマウント時にフラグを立てる
    return () => {
      clearInterval(checkTimer)
      clearInterval(retryTimer)
      unmounted = true
    }
  }, [])

  // 何も描画しない
  return null
}

const appendCacheBuster = (src: string) => {
  const url = new URL(src, window.location.href)
  // 't' クエリパラメータを現在時刻で上書き
  url.searchParams.set('t', Date.now().toString())
  return url.toString()
}
